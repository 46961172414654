import React, { useState, useEffect } from 'react'

import './App.css';
import logo from './images/logo2.svg';
import bg from './images/lawyer-with-weighing-scales.jpg';
import bg_mobile from './images/lawyer-mobile.jpg';
import LeadForm from './Form.js';
import {Carousel, Form, Button, Col, Container, Row, Navbar, Nav} from 'react-bootstrap';
import WhatsAppIcon from './whatsapp/WhatsAppIcon';

function App() { 
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const bgStyles = {backgroundImage: 'url(' + (window.innerWidth > 768 ? bg : bg_mobile) + ')'};
  const [otherActive, setOtherActive] = useState(false);
  const [start, setStart] = useState(false);
  const [step, setStep] = useState(0);
  const [values, setValues] = useState([]);

  useEffect(() => {
    console.log(values);
  });
  
  const renderStepFields = (step_data) => {
      switch (step_data.type) {
          case "select":
            return renderSelectField(step, step_data.select);
          break;
          case "text":
            return renderTextField(step);
          break;
          case "final":
            return renderFinalField();
          break;
      }
  }
  
  const renderFinalField = () => {
      return (
        <Row>
            <Col md="6" className="align-self-center">
                <p>{steps[step].desc}</p>
                <h5>{steps[step].desc2}</h5>
            </Col>
            <Col md="6">
                <LeadForm additional={values} />
            </Col>
        </Row>
      )
  }
  
  const renderTextField = (step) => {
      return (
        <Form.Control type="text" name={"step-"+step+"-text"} onKeyUp={textHandler} defaultValue={(values[step] == 'Другое') ? '' : values[step]} />
      )
  }
  
  const renderSelectField = (step, list) => {
      return (
        <div>
            <Row className="step-select-field">
                {list.map((e, i) => (<Col md="6"><div className="form-check-container"><Form.Check onChange={selectHandler} label={e} checked={values[step] == e} type="radio" name={"step-"+step+"-select"} id={"step-"+step+"-"+i+"-select"} /></div></Col>))}
            </Row>
            { otherActive ? (
            <div className="step-select-field">
                <h5>Опишите подробнее</h5>
                <Form.Control type="text" name={"step-"+step+"-text"} onKeyUp={textHandler} defaultValue={values[step]} />
            </div>
            ) : '' }
        </div>
      )
  }
  
  const prevBtnHandler = () => {
      setStep(step > 0 ? (step-1) : step);
  }
  
  const nextBtnHandler = () => {
      setStep((step + 1) < steps.length ? (step+1) : step);
      setOtherActive(false);
  }

  const selectHandler = (e) => {
      let value = e.target.nextSibling.innerText;
      
      values[step] = e.target.nextSibling.innerText;
      setValues(values);
      
      if (value != 'Другое')
        nextBtnHandler();
      else {
        setOtherActive(true);
      }
  }
  
  const textHandler = (e) => {
      values[step] = e.target.value;
      setValues(values);
      
      forceUpdate();
  }
  
  const steps = [
    {
        title: 'Как назывались мошенники присвоившие Ваши деньги? Напишите название компании.',
        type: 'text',
        required: true
    },{
        title: 'Чем занимается обманувшая Вас компания',
        type: 'select',
        required: true,
        select: [
            'Биржевой брокер',
            'Криптовалютная биржа',
            'Инвестиционный фонд',
            'Букмекер',
            'Другое'
        ]
    },{
        title: 'Сколько времени прошло с момента потери денег?',
        type: 'select',
        required: true,
        select: [
            'Менее трех месяцев',
            'Пол года',
            'До года',
            'Год и более'
        ]
    },{
        title: 'Мошенники не выводят деньги или просто присвоили их?',
        type: 'select',
        required: true,
        select: [
            'Да, мои деньги “на счету”, но их практически невозможно вывести',
            'Нет, мои деньги “слиты”, мошенники не выходят на связь и т.д.'
        ]
    },{
        title: 'Какую сумму Вы потеряли?',
        type: 'select',
        required: true,
        select: [
            'до 1000$',
            'до 10000$',
            'более 25000$',
            'более 25000$'
        ]
    },{
        title: 'Каким образом Вы передавали деньги мошенникам?',
        type: 'select',
        required: true,
        select: [
            'Наличными',
            'Банковским переводом',
            'Криптовалютной транзакцией',
            'Другое'
        ]
    },{
        title: 'Есть ли у Вас документальное подтверждение мошенничества?',
        type: 'select',
        required: true,
        select: [
            'Есть договор, счета и квитанции и пр.',
            'Есть только переписка и электронные формы документов без подписей и печатей',
            'Нет, ничего не сохранилось'
        ]
    },{
        title: 'Возможно есть другие важные детали по Вашему случаю?',
        required: false,
        type: 'text'
    },{
        title: 'Оставьте пожалуйста свои контакты',
        desc: 'Специалисты нашей компании ознакомятся с Вашим случаем, соберут всю имеющуюся информацию об обманувших Вас аферистах, проанализируют способы возврата Ваших средств и свяжутся с Вами. Мы предоставим Вам бесплатную консультацию, расскажем что делать, чтобы вернуть Ваши деньги. Еще раз подчеркнем, что вся полученная от Вас информация является строго конфиденциальной. Кроме того, мы не берем с наших клиентов денег до момента возврата средств. Сначала Вы получаете Ваши деньги, потом мы наше вознаграждение.',
        desc2: 'Не стоит откладывать. Начните возвращать деньги сейчас',
        required: false,
        type: 'final'
    }
  ];
  
  const renderSteps = (
    <div>
        <h4 className="mb-5">{steps[step].title}</h4>
        <div className="step-content pb-5">
            {renderStepFields(steps[step])}
        </div>
        <Row className="steps-info p-4 mt-5">
            <Col md="8" className="col-12 text-center text-md-left mb-3 mb-md-0">Шаг {step+1} из {steps.length}</Col>
            <Col md="4" className="col-12 text-center text-md-right">
                {(step != 0) ? (<Button variant="warning" className="mr-2" onClick={prevBtnHandler}>
                    Назад
                </Button>) : ''}
                {((step+1) != steps.length) ? (<Button onClick={nextBtnHandler} disabled={(steps[step].required == true) && !values[step]}>
                    Далее
                </Button>) : ''}
            </Col>
        </Row>
        <Row className="steps-info p-4 mt-1">
            Для верной оценки Вашего случая и предоставления Вам наиболее развернутой и точной консультации просим Вас ответить на несколько вопросов. Это займет всего пару минут. Полученная нами информация является конфиденциальной. Обращаем Ваше внимание на то, что мы предоставляем консультацию абсолютно БЕСПЛАТНО.
        </Row>
    </div>
  )
  return start ? (
    <div className="quiz-container justify-content-center p-md-5 d-flex">
        <Container className="quiz-inner p-5">
            {renderSteps}
        </Container>
        <WhatsAppIcon />
    </div>
  ) : (
    <div className="App">
        <Row className="fullscreen-block m-0" style={bgStyles}>
            <Col md="8" lg="6" className="offset-0 offset-md-1 offset-lg-1 align-self-center p-5 p-md-4">
                <h2 className="mb-3">Не теряйте время, пора вернуть Ваши деньги</h2>
                <h2 className="mb-3">Окажем профессиональную юридическую помощь в возврате денег. Брокеры-мошенники, букмекеры, финансовые пирамиды и другие аферисты вернут ваши деньги!</h2>
                <p className="mb-5">Быстро. Профессионально. Без предоплат</p>
                <a className="glass-btn text-center" onClick={() => setStart(true)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Вернуть деньги
                </a>
                <a className="glass-btn text-center ml-3" href="/offer">
                    Узнать подробнее >
                </a>
            </Col>
        </Row>
        <WhatsAppIcon />
    </div>
  );
}

export default App;
