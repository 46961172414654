import React from 'react';
import './WhatsAppIcon.css';

const WhatsAppIcon = () => {
  const handleClick = () => {
    window.open('/link.php?link=wa');
  };
  const handleClick2 = () => {
    window.open('/link.php?link=tg');
  }; 
  
  return (
  <>
    <div className="whatsapp-icon" onClick={handleClick}>
      <svg height="512" viewBox="0 0 152 152" width="512" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><radialGradient id="radial-gradient" cx="76" cy="76" gradientUnits="userSpaceOnUse" r="76"><stop offset="0" stop-color="#66bb6a"/><stop offset="1" stop-color="#2e7d32"/></radialGradient><g id="Layer_2" data-name="Layer 2"><g id="Square"><g id="_05.Whatsapp" data-name="05.Whatsapp"><rect id="Background" fill="url(#radial-gradient)" height="152" rx="24" width="152"/><g fill="#fff"><path id="Shade" d="m143 5.27c-10.53 26.42-29.8 55.28-56.12 81.6s-55.19 45.59-81.61 56.13a23.85 23.85 0 0 1 -5.27-15v-104a24 24 0 0 1 24-24h104a23.85 23.85 0 0 1 15 5.27z" opacity=".1"/><path id="Icon" d="m76 36a40 40 0 0 0 -32.39 63.45l-5 14.86 15.39-4.92a40 40 0 1 0 22-73.39zm21.26 56.57-4.26 4.26c-4.48 4.48-16.36-.45-26.89-11s-15.25-22.4-10.99-26.83l4.26-4.26a4.47 4.47 0 0 1 6.05 0l6.28 6.26a4.17 4.17 0 0 1 -1.57 6.94 4.07 4.07 0 0 0 -2.69 4.94c1.12 4.7 7.17 10.53 11.66 11.65a4.27 4.27 0 0 0 4.89-2.72 4.18 4.18 0 0 1 7-1.57l6.27 6.28a4.47 4.47 0 0 1 -.01 6.05z"/></g></g></g></g></svg>
    </div>
            <div className="tg-icon" onClick={handleClick2} dangerouslySetInnerHTML={{__html: '<svg height="512" viewBox="0 0 176 176" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="telegram"><rect id="background" fill="#1c8adb" height="176" rx="24" width="176"/><path id="icon" d="m135.94 45.5-1.82.66-98.78 35.59a3.31 3.31 0 0 0 .29 6.4l25.57 7 4.77 14 4.77 14a4.54 4.54 0 0 0 7.32 1.63l13.21-12.48 25.94 17.59c3.17 2.16 7.69.56 8.5-3l17.18-75.91c.84-3.76-3.12-6.85-6.95-5.48zm-12.61 16.85-44.63 36.48-2.1 1.72a2.27 2.27 0 0 0 -.84 1.48l-.47 3.88-1.29 10.9a.5.5 0 0 1 -1 .08l-3.63-10.89-3.75-11.15a2.26 2.26 0 0 1 1.08-2.67l46.44-26.62 8.74-5c1.27-.74 2.57.87 1.45 1.79z" fill="#fff"/></g></g></svg>'}} >
        </div>    
    </>    
  );
};

export default WhatsAppIcon;