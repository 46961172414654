import * as React from "react";
import Home from "./Home";
import Offer from "../src/pages/Layout1/Layout1";
import { Routes, Route, Outlet, Link } from "react-router-dom";

export default function App() {
  return (
    <div>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/offer" element={<Offer />} /> 
      </Routes>
    </div>
  );
}